export function assertWithResponse(condition: any, message: string, responseInit?: ResponseInit): asserts condition {
  if (!condition) {
    throw new Response(message, responseInit);
  }
}

export function assertAllowedMethod(request: Request, ...methods: string[]) {
  assertWithResponse(methods.includes(request.method), 'Method Not Allowed', {status: 405});
}

export function assertAuthorizedWithResponse(request: Request) {
  const isAuthorized = (request: Request) => {
    const header = request.headers.get('Authorization');
    if (!header) return false;
    const base64 = header.replace('Basic ', '');
    const [username, password] = Buffer.from(base64, 'base64').toString().split(':');
    return username === process.env.USERNAME && password === process.env.PASSWORD;
  };

  assertWithResponse(isAuthorized(request), 'Unauthorized', {status: 401});
}
